export function intersectionObserver(el, fn, options) {
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      fn(entry)
    })
  }, options)

  observer.observe(el)

  return { cancel() { observer.unobserve(el) } }
}
