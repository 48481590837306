import EventEmitter from 'eventemitter3'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

export const bus = new EventEmitter()

window.addEventListener('touchstart', handleTouchStart, false)
window.addEventListener('resize', debounce(_ => bus.emit('windowResize'), 100), false)
window.addEventListener('scroll', throttle(evt => bus.emit('windowScroll', evt), 100), false)

function handleTouchStart(evt) {
  bus.emit('isTouch', { origEvent: evt })
  window.removeEventListener('touchstart', handleTouchStart)
}
