/* global YT */
import { loadAPI } from '/machinery/youtube'
import UI from '/machinery/UI'

const STATE_PLAYING = 1
const IS_PLAYING = 'is-playing'
const players = {}

UI({
  component: '.js-video',
  playButton: '.js-video-playButton',
  player: '.js-video-player',
}, ({ component, playButton, player }) => {
  const id = player.getAttribute('id')

  const callback = () => {
    players[id] = new YT.Player(id, {
      videoId: id,
      playerVars: {
        autoplay: 0,
        controls: 1,
        enablejsapi: 1,
        rel: 0,
        modestbranding: 1,
      },
      events: {
        onReady: _ => { onPlayerReady(id) },
        onStateChange: _ => { onPlayerStateChange(id) },
      },
    })
  }

  loadAPI(callback)

  function onPlayerReady(id) {
    playButton.addEventListener('click', _ => { handleClickPlayButton(id) }, false)
  }

  function onPlayerStateChange(id) {
    if (!players[id]) return

    if (players[id].getPlayerState() === STATE_PLAYING) {
      playButton.classList.add(IS_PLAYING)
    }
  }

  function play(id) {
    Object.keys(players).forEach(playerId => {
      if (playerId !== id && players[playerId].getPlayerState() === STATE_PLAYING) {
        players[playerId].pauseVideo()
      }
    })

    players[id].playVideo()
  }

  function handleClickPlayButton(id) {
    play(id)
  }
})
