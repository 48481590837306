import UI from '/machinery/UI'
import { track } from './tracking'

const attribute = 'data-tracking'
const attributeLabel = 'data-tracking-label'
const trackingTypes = {
  accordion: trackAccordion,
  button: trackButton,
  contact: trackContact,
  externalLink: trackExternalLink,
  languageSwitch: trackLanguageSwitch,
  social: trackSocial,
  tickets: trackTickets,
}

UI({
  component: `[${attribute}]`,
}, ({ component }) => {
  const type = component.getAttribute(attribute)
  const { origin = '' } = window.location

  const anchorElements = [...Array.from(document.querySelectorAll('a[href]:not([data-tracking])'))].filter(
    x => x.href && x.href.substr(0, origin.length) !== origin
  )

  anchorElements.forEach(element => element.addEventListener('click', trackingTypes['externalLink']))

  trackingTypes[type] && component.addEventListener('click', trackingTypes[type], false)
})

function trackAccordion(clickEvent) {
  const { currentTarget } = clickEvent

  track({
    event: 'accordion_toggled',
    category: 'accordion',
    action: 'toggled',
    label: currentTarget.innerText
  })
}

function trackButton(clickEvent) {
  const { currentTarget } = clickEvent

  track({
    event: 'button_clicked',
    category: 'button',
    action: 'clicked',
    label: `${currentTarget.getAttribute('href') || ''} | ${currentTarget.innerText}`,
  })
}

function trackContact(clickEvent) {
  const { currentTarget } = clickEvent
  const label = currentTarget.getAttribute(attributeLabel)

  track({
    event: 'contact_clicked',
    category: 'contact',
    action: 'clicked',
    label: label || currentTarget.innerText,
  })
}

function trackExternalLink(clickEvent) {
  const { currentTarget } = clickEvent

  track({
    event: 'external-link_clicked',
    category: 'external-link',
    action: 'clicked',
    label: `${currentTarget.getAttribute('href') || ''} | ${currentTarget.innerText}`,
  })
}

function trackLanguageSwitch(clickEvent) {
  const { currentTarget, target } = clickEvent
  const label = currentTarget.getAttribute(attributeLabel)

  clickEvent.preventDefault()

  track({
    event: 'language_changed',
    category: 'language',
    action: 'changed',
    label: label || currentTarget.innerText,
    eventCallback: _ => {
      window.location.href = target.href
    }
  })
}

function trackSocial(clickEvent) {
  const { currentTarget } = clickEvent

  track({
    event: 'social_clicked',
    category: 'social',
    action: 'clicked',
    label: currentTarget.innerText,
  })
}

function trackTickets(clickEvent) {
  const { currentTarget } = clickEvent

  track({
    event: 'tickets_clicked',
    category: 'ticket',
    action: 'clicked',
    label: currentTarget.innerText,
  })
}
