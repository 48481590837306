import { allowBodyScroll, preventBodyScroll } from '/machinery/bodyScroll'
import { bus } from '/machinery/bus'
import UI from '/machinery/UI'

export function openModal(id) {
  bus.emit(`modal:show:${id}`)
}

UI({
  component: '.js-theme-modal',
  background: '.js-theme-modal-bg',
  close: ['.js-theme-modal-close']
}, ({ component, background, close }) => {
  const id = component.dataset.name
  bus.on(`modal:show:${id}`, openModal)

  function openModal() {
    const bodyElement = document.createElement('div')
    preventBodyScroll()
    bodyElement.classList.add('portal')

    document.body.appendChild(bodyElement)
    bodyElement.appendChild(component)

    component.classList.add('is-open')

    close.addEventListener('click', closeModal)
    background.addEventListener('click', closeModal)
    document.addEventListener('keydown', handleKeyDown)

    component.animate([{ opacity: '0' }, { opacity: '1' }], { duration: 300, fill: 'forwards' })
  }

  function closeModal() {
    const bodyElement = document.querySelector('.portal')
    allowBodyScroll()

    component.animate([{ opacity: '1' }, { opacity: '0' }], { duration: 300, fill: 'forwards' }).onfinish = function() {
      bodyElement.removeChild(component)
      document.body.removeChild(bodyElement)
    }

    component.classList.remove('is-open')

    close.removeEventListener('click', closeModal)
    background.removeEventListener('click', closeModal)
    document.removeEventListener('keydown', handleKeyDown)
  }

  function handleKeyDown(event) {
    if (event.keyCode === 27) {
      closeModal()
    }
  }
})

