/**

  Helper for resolving dom nodes within a container.
  - Converts NodeLists to Arrays
  - Adds useful method to the Array (like `addEventListener`, `removeEventListener` on the collection)
  - The callback is triggered for every occurance of the component in the current DOM

  usage: `UI({ component: '.js-Component', someNestedElement: '.js-Component-nestedElement' }, (ui = { component, someNestedComponent }) => { ... })`

  example:
  ```
  import UI from 'utils/UI'

  UI({
    // required root-`component` key, other selectors will be found inside this element
    component: '.js-Component',

    // `single` selector (`document.querySelector`)
    someElement: '.js-Component-singleSelector',

    // `multiple` selector (`document.querySelectorAll`, mapped from NodeList to Array)
    someListOfElements: ['.js-Component-multipleSelectors']
  }, ui => {
    ui.component.classList.toggle('whatever')
    ui.someListOfElements.forEach(e => e.classList.toggle('whatever'))
    ui.someListOfElements.addEventListener(...) // bind event to all nodes in array
  })
**/

export default function UI(props, callback) {
  if (!props.component) throw new Error('UI expects at least a `component` selector')

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', _ => ready(props, callback))
  } else {  // `DOMContentLoaded` already fired
    ready(props, callback)
  }
}

function ready(props, callback) {
  const container = props.componentContainer || document
  const componentElements = getComponentElements(props.component, container)
  if (componentElements.length) {
    return componentElements.map(component => callback(getUi({ ...props, component })))
  }
}

function getComponentElements(component, container) {
  return typeof component === 'string'
    ? [...Array.from(container.querySelectorAll(component))]
    : component instanceof Element
      ? [component]
      : component
}

function getUi(props) {
  /* eslint-disable no-unused-vars */
  const { component, componentContainer, ...propsWithoutComponent } = props
  /* eslint-enable no-unused-vars */
  return Object.keys(propsWithoutComponent).reduce((mem, key) => ({
    ...mem,
    [key]: props[key] instanceof Array
      ? $ify(props[key].reduce((all, s) => [...all, ...Array.from(mem.component.querySelectorAll(s))], []))
      : mem.component.querySelector(props[key])
  }), { component })
}

function $ify(els) {
  els.addEventListener = (...args) => els.forEach(e => e.addEventListener(...args))
  els.removeEventListener = (...args) => els.forEach(e => e.removeEventListener(...args))

  return els
}
