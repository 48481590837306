const callbacks = []

/* eslint-disable no-unused-vars */
function onYouTubeIframeAPIReady() {
/* eslint-enable no-unused-vars */
  callbacks.forEach(callback => callback())
}

if (typeof window !== 'undefined') {
  window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady
}

export function loadAPI(callback) {
  if (typeof YT !== 'undefined') {
    callback()
    return
  }

  callbacks.push(callback)

  const tag = document.createElement('script')
  tag.src = 'https://www.youtube.com/iframe_api'
  var firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
}
