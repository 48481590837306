export function onElementResize(target, callback) {
  const observer = new ResizeObserver(([entry]) => {
    callback({
      width: entry.contentRect.width,
      height: entry.contentRect.height
    })
  })

  observer.observe(target)
  return () => observer.disconnect()
}
