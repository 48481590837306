import { intersectionObserver } from '/machinery/intersectionObserver'
import { prefersReducedMotion } from '/machinery/prefersReducedMotion'
import UI from '/machinery/UI'

const parallaxSpeed = 'data-parallax-speed'
const parallaxContinuous = 'data-parallax-continuous'

UI({
  component: '.js-parallax',
  container: '.js-parallax-container',
  contra: '.js-parallax-contra',
}, ({ component, container, contra }) => {
  const state = {
    speed: -0.1,
    continuous: false,
  }
  if (prefersReducedMotion()) return

  if (component.getAttribute(parallaxSpeed)) {
    state.speed = parseFloat(component.getAttribute(parallaxSpeed))
  }

  if (component.getAttribute(parallaxContinuous)) {
    state.continuous = !!(component.getAttribute(parallaxContinuous))
  }

  const steps = parseInt(window.innerHeight, 10)
  const threshold = Array.from(Array(steps), (_, x) => x / steps)

  const callback = entry => {
    const { boundingClientRect, intersectionRatio } = entry
    const { top } = boundingClientRect

    let ratio = intersectionRatio

    if (state.continuous && top < 0) {
      ratio = 2 - intersectionRatio
    }

    const y = (100 - (ratio * 100)) * state.speed
    container.style.transform = `translateY(${y}%)`

    if (contra) {
      contra.style.transform = `translateY(${y * -1}%)`
    }
  }

  const options = {
    threshold,
  }

  intersectionObserver(component, callback, options)
})
