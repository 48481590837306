import UI from '/machinery/UI'

UI({
  component: '.js-opening-hours',
  item: ['.js-opening-hours-listItem']
}, ({ item }) => {
  let currentDay = new Date().getDay()
  currentDay = currentDay === 0 ? 6 : currentDay - 1
  item[currentDay].classList.add('is-current')
})
