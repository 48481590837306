import { intersectionObserver } from '/machinery/intersectionObserver'
import { toggleClass } from '/machinery/toggleClass'
import { bus } from '/machinery/bus'
import UI from '/machinery/UI'

const attribute = 'data-site-header'

UI({
  component: '.js-siteHeader',
  logoDesktop: '.js-SiteHeader-logo-desktop',
  logoMobile: '.js-SiteHeader-logo-mobile',
}, ({ component, logoDesktop, logoMobile }) => {
  const state = {
    menuOpen: false
  }

  bus.on('handleOpenMenu', handleOpenMenu)
  bus.on('handleCloseMenu', handleCloseMenu)

  const elements = [...Array.from(document.querySelectorAll(`[${[attribute]}]`))]
  const threshold = 0.1
  const callback = entry => {
    const { target } = entry

    if (target.getAttribute(attribute) === 'transparent') {
      toggleClass(component, 'is-transparent', entry.intersectionRatio > threshold)
    }
  }

  const options = {
    threshold,
  }

  elements.forEach(element => {
    intersectionObserver(element, callback, options)
  })
  logoMobile.classList.add('isVisible')

  function handleOpenMenu() {
    state.menuOpen = true
    updateUi()
  }

  function handleCloseMenu() {
    state.menuOpen = false
    updateUi()
  }

  function updateUi() {
    logoDesktop.classList.toggle('isVisible', state.menuOpen)
    logoMobile.classList.toggle('isVisible', !state.menuOpen)
  }
})
