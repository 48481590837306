import { KEY_TAB } from '/machinery/keys'

const attribute = 'data-context-tabbing-user'

window.addEventListener('keydown', handleKeyDown)

function handleKeyDown(event) {
  if (event.keyCode === KEY_TAB) {
    document.body.setAttribute(attribute, 'true')
    window.removeEventListener('keydown', handleKeyDown)
  }
}
