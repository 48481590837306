import { computePosition } from '@floating-ui/dom'
import UI from '/machinery/UI'

UI({
  component: '.js-HeroThemeSwitcher',
  button: '.js-HeroThemeSwitcher-button',
  buttonIcon: '.js-HeroThemeSwitcher-buttonIcon',
  menu: '.js-HeroThemeSwitcher-menu',
},
({ button, buttonIcon, menu }) => {
  const state = {
    expanded: false
  }

  button.addEventListener('click', handleClick, false)

  function handleClick(event) {
    event.preventDefault()
    state.expanded = !state.expanded
    updateUI()
  }

  function updateUI() {
    const buttonHeight = button.offsetHeight + 10
    const buttonWidth = button.offsetWidth + 20
    const { expanded } = state

    button.setAttribute('aria-expanded', expanded ? 'true' : 'false')
    menu.setAttribute('aria-hidden', expanded ? 'false' : 'true')
    buttonIcon.classList.toggle('is-expanded', expanded)

    computePosition(button, menu, {
      placement: 'bottom-start',
    }).then(({ y }) => {
      Object.assign(menu.style, {
        left: '-10px',
        top: `${y + -buttonHeight}px`,
        paddingTop: `${buttonHeight}px`,
        width: `${buttonWidth}px`
      })
    })
  }
})
