let stack = []
const attribute = 'data-prevent-body-scroll'
let scrollY

export function allowBodyScroll(key) {
  stack = stack.filter(item => item !== key)

  if (stack.length === 0) {
    const { body, documentElement } = document
    body.removeAttribute(attribute)
    body.style.position = ''
    body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0', 10))
    documentElement.style.removeProperty('scroll-behavior')
    documentElement.style.removeProperty('overflow-y')
    scrollY = 0
  }
}

export function preventBodyScroll(key) {
  const { body, documentElement } = document
  if (typeof stack[key] === 'undefined') {
    stack.push(key)
  }

  if (!scrollY) {
    scrollY = window.scrollY
  }

  const forceScrollbar = body.scrollHeight > window.innerHeight

  body.style.position = 'fixed'
  body.style.top = `-${scrollY}px`
  if (forceScrollbar) {
    documentElement.style.overflowY = 'scroll'
  }
  documentElement.style.scrollBehavior = 'unset'
  body.setAttribute(attribute, true)
}
