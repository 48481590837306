import { toggleClass } from '/machinery/toggleClass'
import { onElementResize } from '/machinery/onElementResize'
import UI from '/machinery/UI'

UI({
  component: '.js-Accordion',
  toggle: '.js-Accordion-toggle',
  region: '.js-Accordion-region',
  inner: '.js-Accordion-inner',
}, ({ component, toggle, region, inner }) => {
  const state = {
    expanded: region.getAttribute('aria-expanded') !== 'false',
    contentHeight: 0
  }

  toggle.addEventListener('click', handleClickToggle, false)
  updateUI()


  onElementResize(inner, ({ height }) => {
    state.contentHeight = height
    updateUI()
  })

  function handleClickToggle(event) {
    state.expanded = !state.expanded
    event.preventDefault()
    updateUI()
  }

  function updateUI() {
    const { expanded } = state
    toggleClass(component, 'is-expanded', expanded)
    region.style.animationName = state.expanded ? 'accordionTranslateUp' : 'accordionTranslateDown'
    region.setAttribute('aria-hidden', expanded ? 'false' : 'true')
    region.style.height = state.expanded ? state.contentHeight + 'px' : 0
  }
})
