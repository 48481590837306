export function trackCookieConsentGiven({ accepted }) {
  window.dataLayer && window.dataLayer.push({
    event: 'cookie_consent_given',
    advertisingFeatures: {
      anonymizeIp: accepted ? 'false' : 'true',
      allowAdFeatures: accepted ? 'true' : 'false'
    }
  })
}

export function trackOfferRequested() {
  track({
    event: 'offer_requested',
    category: 'offer',
    action: 'requested',
  })
}

export function trackReservationRequested(reservationType) {
  track({
    event: 'reservation_requested',
    category: 'reservation',
    action: 'requested',
    label: reservationType,
  })
}

export function trackMapMarkerOpened(label) {
  track({
    event: 'mapmarker_opened',
    category: 'marker',
    action: 'opened',
    label,
  })
}

export function track({
  event,
  category,
  action,
  label = '',
  value = undefined,
  nonInteraction = false,
  eventCallback = undefined,
  eventTimeout = 1000
}) {
  if (window.dataLayer && window.google_tag_manager) {
    window.dataLayer.push({
      event,
      event_tracking: { category, action, label, value, 'non-interaction': nonInteraction },
      ...(eventCallback && { eventCallback, eventTimeout }),
    })
  } else if (typeof eventCallback === 'function') {
    eventCallback()
  }
}
