import { bus } from '/machinery/bus'
import defer from 'lodash/defer'
import Flickity from 'flickity'
import { toggleClass } from '/machinery/toggleClass'
import UI from '/machinery/UI'

const attributeFirst = 'data-context-carousel-first'
const attributeLast = 'data-context-carousel-last'

UI({
  component: '.js-carouselIfNecessary',
  cells: ['.js-carouselIfNecessary-cell'],
}, ({ component, cells }) => {
  const state = {
    cellsWidth: getCombinedWidth(cells),
    containerWidth: component.offsetWidth,
    emitComponents: [],
    emitEvent: null,
    flickity: null,
    mql: null,
  }

  const context = component.getAttribute('data-context')
  const contextElement = context ? document.querySelector(context) : null

  const prevButton = component.parentNode.querySelector('.js-carouselIfNecessary-prevButton')
  const nextButton = component.parentNode.querySelector('.js-carouselIfNecessary-nextButton')
  component.classList.add('CarouselIfNecessary')

  const cellThreshold = parseInt(component.dataset.cellThreshold, 10) || 1
  if (cells?.length > cellThreshold) {
    check()
    bus.on('windowResize', handleResize)
  } else {
    component.classList.add('is-not-with-carousel')
  }

  function handleResize() {
    state.cellsWidth = getCombinedWidth(cells)
    state.containerWidth = component.offsetWidth
    check()
    cells.forEach(x => (x.style.height = 'auto'))
    state.flickity && state.flickity.resize()
    state.flickity && state.flickity.reposition()
    cells.forEach(x => (x.style.height = '100%'))
  }

  function check() {
    const { cellsWidth, containerWidth } = state
    cellsWidth > containerWidth ? createCarousel() : destroyCarousel()

    if (!state.emitComponents?.length && component.getAttribute('data-emit-components')) {
      state.emitComponents = component.getAttribute('data-emit-components').split('>||<')
    }

    if (!state.emitEvent && component.getAttribute('data-emit-event')) {
      state.emitEvent = component.getAttribute('data-emit-event')
    }
  }

  function createCarousel() {
    component.classList.add('is-with-carousel')
    component.classList.remove('is-not-with-carousel')

    defer(_ => {
      if (!state.flickity) {
        const initialIndex = component.dataset.initialIndex ? parseInt(component.dataset.initialIndex, 10) : 0
        const draggable = component.dataset.draggable !== 'false'
        const contain = component.dataset.contain !== 'false'
        let cellAlign = component.dataset.cellAlign
        if (typeof cellAlign === 'undefined') {
          cellAlign = contain ? 'left' : 'center'
        }
        state.flickity = new Flickity(component, {
          prevNextButtons: component.dataset.prevNextButtons === 'true',
          pageDots: component.dataset.pageDots === 'true',
          cellAlign,
          contain,
          draggable,
          groupCells: contain,
          initialIndex,
          autoPlay: component.dataset.autoplay ? Number(component.dataset.autoplay) : false,
          wrapAround: component.dataset.wraparound,
          on: {
            ready: _ => {
              cells.forEach(x => (x.style.height = '100%'))
              maybeEmitEvent(initialIndex)
            },
            change: index => {
              updateVisibilityPrevNextButton(index)
              if (contextElement) updateFirstLastAttributes(index)
            },
            dragStart: (index) => {
              if (contextElement) updateFirstLastAttributes(-1)
            },
            settle: index => {
              maybeEmitEvent(index)
              if (contextElement) updateFirstLastAttributes(index)
            }
          },
        })

        prevButton && prevButton.addEventListener('click', handleClickPrevButton, false)
        nextButton && nextButton.addEventListener('click', handleClickNextButton, false)

        updateVisibilityPrevNextButton(initialIndex)
        if (contextElement) updateFirstLastAttributes(initialIndex)
      }
    })
  }

  function updateVisibilityPrevNextButton(index) {
    if (!state.flickity) return

    const { slides } = state.flickity
    prevButton && toggleClass(prevButton, 'is-visible', index !== 0)
    nextButton && toggleClass(nextButton, 'is-visible', (index + 1) < slides?.length)
  }

  function updateFirstLastAttributes(index) {
    if (!state.flickity) return

    const { slides } = state.flickity
    contextElement.setAttribute(attributeFirst, index === 0)
    contextElement.setAttribute(attributeLast, (index + 1) >= slides?.length)
  }

  function maybeEmitEvent(index) {
    const { emitComponents, emitEvent } = state

    if (emitEvent && emitComponents) {
      emitComponents.forEach((component, i) => {
        bus.emit(`${emitEvent}:${component}:${i === index ? 'is-active' : 'not-active'}`)
      })
    }
  }

  function destroyCarousel() {
    component.classList.remove('is-with-carousel')
    component.classList.add('is-not-with-carousel')
    defer(_ => {
      if (state.flickity) {
        state.flickity.destroy()
        state.flickity = null
      }
      cells.forEach(x => (x.style.height = 'auto'))
    })
  }

  function handleClickPrevButton() {
    state.flickity && state.flickity.previous()
  }

  function handleClickNextButton() {
    state.flickity && state.flickity.next()
  }
})

function getCombinedWidth(els) {
  return els.reduce((res, x) => {
    const style = window.getComputedStyle(x)
    return res + x.offsetWidth + parseInt(style.marginRight, 10) + parseInt(style.marginLeft, 10)
  }, 0)
}
