import { openModal } from '/includes/theme-modal/theme-modal'
import UI from '/machinery/UI'

UI({
  component: '.js-themeCard',
}, ({ component }) => {
  component.addEventListener('click', handleClick, false)

  function handleClick(event) {
    event.preventDefault()
    updateUI(event.currentTarget.id)
  }

  function updateUI(id) {
    openModal(id)
  }
})
