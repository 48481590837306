import UI from '/machinery/UI'
import { onElementResize } from '/machinery/onElementResize'

UI({
  component: '.js-LanguageSwitcherMobile',
  button: '.js-LanguageSwitcherMobile-button',
  inner: '.js-LanguageSwitcherMobile-inner',
  content: '.js-LanguageSwitcherMobile-content',
  expand: '.js-LanguageSwitcherMobile-list'
}, ({ button, inner, expand, content }) => {
  const state = {
    expanded: false,
    contentHeight: 0
  }

  button.addEventListener('click', handleClick)
  updateUi()

  onElementResize(inner, ({ height }) => {
    state.contentHeight = height
    updateUi()
  })

  function handleClick() {
    state.expanded = !state.expanded
    updateUi()
  }

  function updateUi() {
    if (state.expanded) {
      button.classList.add('is-open')
      button.setAttribute('aria-expanded', true)
      expand.setAttribute('aria-hidden', false)
      content.classList.add('is-active')
    } else {
      button.classList.remove('is-open')
      button.setAttribute('aria-expanded', false)
      expand.setAttribute('aria-hidden', true)
      content.classList.remove('is-active')
    }
    expand.style.height = state.expanded ? state.contentHeight + 40 + 'px' : 0
  }

})
