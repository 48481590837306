/*
  IE11 doesn't support the second parameter of classList.toggle
  this function works around this bug

  https://developer.mozilla.org/en-US/docs/Web/API/Element/classList
  https://connect.microsoft.com/IE/feedback/details/878564/element-classlist-toggle-does-not-support-second-parameter
*/
export function toggleClass(el, className, condition) {
  if (condition === 'undefined') {
    return el.classList.toggle(className)
  } else {
    el.classList[condition ? 'add' : 'remove'](className)
    return condition
  }
}
