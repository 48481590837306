import { bus } from '/machinery/bus'
import UI from '/machinery/UI'

const maintenance = 'spoorwegmuseum-maintenance'
const targetAttribute = 'data-context-site-maintenance-target'

UI({
  component: '.js-siteMaintenance',
  closeButton: '.js-siteMaintenance-close',
}, ({ component, closeButton }) => {
  closeButton.addEventListener('click', handleCloseClick, false)
  bus.on('windowResize', handleResize)

  const targetElements = [...Array.from(document.querySelectorAll(`[${targetAttribute}]`))]

  if (!sessionStorage.getItem(maintenance)) {
    showMessage()
    handleResize()
  }

  function handleCloseClick(evt) {
    evt.preventDefault()
    evt.currentTarget.blur()
    setSessionStorageMaintenance()
    close()
  }

  function setSessionStorageMaintenance() {
    sessionStorage.setItem(maintenance, 'true')
  }

  function handleResize() {
    if (!sessionStorage.getItem(maintenance)) {
      const { height } = component.getBoundingClientRect()
      setTargetElementsMargin(`${height}px`)
    }
  }

  function showMessage() {
    component.classList.add('is-visible')
  }

  function close() {
    component.classList.remove('is-visible')
    component.classList.add('is-closed')
    setTargetElementsMargin('0px')
  }

  function setTargetElementsMargin(value) {
    targetElements.forEach(element => {
      element.style.marginTop = value
    })
  }
})
