import { KEY_ESC } from '/machinery/keys'
import { toggleClass } from '/machinery/toggleClass'
import { allowBodyScroll, preventBodyScroll } from '/machinery/bodyScroll'
import { bus } from '/machinery/bus'
import UI from '/machinery/UI'

const STATE_IS_CLOSED = 'is-closed'
const STATE_IS_OPEN = 'is-open'
const CONTEXT_MENU_STATE = 'data-context-menu-state'

UI({
  component: '.js-menu',
  close: ['.js-menuClose'],
  search: '.js-Menu-search',
  languageSwitch: '.js-Menu-languageSwitcher-button',
  parentLink: ['.js-parent > .js-link'],
  menuIcon: '.js-menuIcon-hamburger',
  toggle: '.js-menuToggle',
}, ({ component, close, languageSwitch, parentLink, toggle, menuIcon, search }) => {
  const state = {
    activeSubMenu: null,
    isOpen: false,
  }
  parentLink.forEach(element => {
    element.addEventListener('click', handleClickParentLink, false)
  })

  languageSwitch.addEventListener('click', handleClickParentLink, false)

  close.forEach(element => {
    element.addEventListener('click', handleClickClose, false)
  })

  toggle.addEventListener('click', handleClickToggle, false)
  function closeMenu() {
    closeSubMenu()
    state.isOpen = false
    bus.emit('handleCloseMenu')
    if (!state.isOpen) {
      search.classList.remove('is-open')
      menuIcon.classList.remove('is-open')
      component.classList.remove('is-open')
    }
    component.classList.remove(!state.isOpen && 'is-open')
    document.body.setAttribute(CONTEXT_MENU_STATE, STATE_IS_CLOSED)

    toggleBodyScroll()
  }

  function closeSubMenu() {
    if (state.activeSubMenu) {
      toggleClass(state.activeSubMenu.parentNode, STATE_IS_OPEN, false)
      toggleClass(state.activeSubMenu, STATE_IS_OPEN, false)
      state.activeSubMenu = null
    }
  }

  function openMenu() {
    state.isOpen = true
    bus.emit('handleOpenMenu')
    if (state.isOpen) {
      search.classList.add('is-open')
      menuIcon.classList.add('is-open')
      component.classList.add('is-open')
    }
    document.body.setAttribute(CONTEXT_MENU_STATE, STATE_IS_OPEN)
    component.classList.add(state.isOpen && 'is-open')
    toggleBodyScroll()
  }

  function openSubMenu(element) {
    toggleClass(element.parentNode, STATE_IS_OPEN, true)
    toggleClass(element, STATE_IS_OPEN, true)
    state.activeSubMenu = element
    toggleBodyScroll()
  }

  function toggleBodyScroll() {
    if (state.isOpen) {
      preventBodyScroll()
      document.addEventListener('keydown', handleKeyDown)
    } else {
      allowBodyScroll()
      document.removeEventListener('keydown', handleKeyDown)
    }
  }

  function handleClickParentLink(event) {
    event.preventDefault()
    event.stopPropagation()

    const isMobile = toggle.offsetParent !== null
    openMenu()

    const { activeSubMenu } = state

    closeSubMenu()

    if (!isMobile || activeSubMenu !== event.currentTarget) {
      openSubMenu(event.currentTarget)
    }
  }

  function handleClickClose(event) {
    event.preventDefault()
    closeMenu()
  }

  function handleClickToggle(event) {
    event.preventDefault()
    if (state.isOpen) {
      closeMenu()
    } else {
      openMenu()
    }
  }

  function handleKeyDown(event) {
    if (event.keyCode === KEY_ESC) {
      event.preventDefault()
      closeMenu()
    }
  }
})
