import UI from '/machinery/UI'
import { reportError } from '/machinery/reportError'

UI(
  {
    component: '.js-instagramThumbnail',
    image: '.js-instagramThumbnail-image',
    author: '.js-instagramThumbnail-author',
    attribution: '.js-instagramThumbnail-attribution',
    error: '.js-instagramThumbnail-error',
  },
  async ({ component, image, author, attribution, error }) => {
    try {
      const { data, status } = await fetchOembed(component.dataset.postUrl)

      switch (status) {
        case 'error':
          // als de fetch of het parsen van json mis gaat tonen we een default error message
          error.removeAttribute('hidden')
          // met een link naar de relevante Instagram post
          component.removeAttribute('hidden')
          break
        case 'notFound':
          // component remains hidden
          break
        default:
          image.setAttribute('src', data.thumbnail_url)
          image.setAttribute('alt', data.title)
          image.setAttribute('width', data.thumbnail_width)
          image.setAttribute('height', data.thumbnail_height)
          author.setAttribute('href', `${data.provider_url + data.author_name}`)
          author.textContent = `@${data.author_name}`
          attribution.removeAttribute('hidden')
          component.removeAttribute('hidden')
      }
    } catch (err) {
      reportError(err)
    }

    async function fetchOembed(postUrl) {
      const appId = component.dataset.appId
      const clientToken = component.dataset.clientToken

      return fetch(`https://graph.facebook.com/v10.0/instagram_oembed?url=${postUrl}&access_token=${appId + '|' + clientToken}`)
        .then(async (x) => (x.ok ? { data: await x.json(), status: 'found' } : { status: 'notFound' }))
        .catch(() => ({ status: 'error' }))
    }
  },
)
