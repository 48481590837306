import { computePosition } from '@floating-ui/dom'
import UI from '/machinery/UI'

UI({
  component: '.js-AnchorSubmenu',
  button: '.js-AnchorSubmenu-button',
  buttonIcon: '.js-AnchorSubmenu-buttonIcon',
  menu: '.js-AnchorSubmenu-menu',
  menuItem: ['.js-AnchorSubmenu-menuLink']
},
({ button, buttonIcon, menu, menuItem }) => {
  const state = {
    expanded: false
  }

  button.addEventListener('click', handleClick, false)

  menuItem.forEach((item) => {
    item.addEventListener('click', handleMenuItemClick, false)
  })

  function handleMenuItemClick() {
    state.expanded = !state.expanded
    updateUI()
  }

  function handleClick(event) {
    event.preventDefault()
    state.expanded = !state.expanded
    updateUI()
  }

  function updateUI() {
    const buttonHeight = button.offsetHeight + 10
    const buttonWidth = button.offsetWidth + 20
    const { expanded } = state

    button.setAttribute('aria-expanded', expanded ? 'true' : 'false')
    menu.setAttribute('aria-hidden', expanded ? 'false' : 'true')
    buttonIcon.classList.toggle('is-expanded', expanded)

    computePosition(button, menu, {
      placement: 'bottom-start',
    }).then(({ y }) => {
      Object.assign(menu.style, {
        left: '-10px',
        top: `${y + -buttonHeight}px`,
        paddingTop: `${buttonHeight}px`,
        width: `${buttonWidth}px`
      })
    })
  }
})
