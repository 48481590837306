import UI from '/machinery/UI'

UI({
  component: '.js-ImageWithLinks',
  visuals: ['.js-ImageWithLinks-visual'],
  links: ['.js-ImageWithLinks-link'],
},
({ component, visuals, links }) => {
  const state = {
    id: visuals[0].dataset.id
  }

  updateUI()

  links.forEach((link) => {
    link.addEventListener('mouseover', function(event) {
      state.id = event.currentTarget.id
      updateUI()
    })
  })

  function updateUI() {
    visuals.forEach((visual) => {
      visual.classList.toggle('is-active', visual.dataset.id === state.id)
    })
  }
})
