import { prefersReducedMotion } from '/machinery/prefersReducedMotion'
import { intersectionObserver } from '/machinery/intersectionObserver'
import UI from '/machinery/UI'

UI({
  component: '.js-horizontalScroll',
  container: '.js-horizontalScroll-container',
  observer: '.js-horizontalScroll-observer',
}, ({ component, container, observer }) => {
  if (prefersReducedMotion()) return

  const scope = document.querySelector('main')
  const steps = parseInt(window.innerWidth, 10)
  const threshold = Array.from(Array(steps), (_, x) => x / steps)
  const visibilityRatio = getVisibilityRatio()
  const speed = 1.75
  const offsetLeft = parseInt(component.parentNode.clientWidth, 10) * 0.75

  if (visibilityRatio && visibilityRatio < 1) {
    // Shift observer's top
    observer.style.transform = `translateY(${(visibilityRatio - 1) * 100}%)`
  }

  const callback = entry => {
    const { boundingClientRect } = entry
    const { height, top } = boundingClientRect

    container.style.transform = `translateX(${offsetLeft}px) translateX(${(top - height) * speed}px)`
  }

  const options = {
    threshold,
  }

  intersectionObserver(observer, callback, options)

  function getVisibilityRatio() {
    const offsetTop = getOffsetTop(observer)
    const clientHeight = observer.clientHeight
    const delta = scope.clientHeight - offsetTop - clientHeight

    if (delta < 0) {
      return 1 - (delta / clientHeight * -1)
    }

    return 1
  }

  function getOffsetTop(element) {
    let offsetTop = 0
    while (element) {
      offsetTop += element.offsetTop
      element = element.offsetParent
    }
    return offsetTop
  }
})
