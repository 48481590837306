import UI from '/machinery/UI'

UI({
  component: '.js-PageKinderenVanVersteegDetail',
}, ({ component }) => {
  const sections = Array.from(component.querySelectorAll('section'))
  sections.forEach((section, index) => {
    if (index % 2 === 1) {
      section.classList.add('colorScheme--yellow-light')
    }
  })
})
