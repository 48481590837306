import UI from '/machinery/UI'

const CONTEXT_TRACK_ANIMATION = 'data-context-track-animation'

UI({
  component: '.js-trackAnimation',
}, ({ component }) => {
  const state = component.getAttribute(CONTEXT_TRACK_ANIMATION)

  if (state === 'auto') {
    window.setTimeout(() => {
      component.setAttribute(CONTEXT_TRACK_ANIMATION, 'start')
    }, 100)
  }
})
